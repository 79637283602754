import React from 'react'
import Layout from '../../staticcomponents/layout'
import './privacynotice.scss'
import Grid from '@material-ui/core/Grid';
import PrivacyContent from './privacycontent';
import Container from '@material-ui/core/Container';



export default function PrivacyNotice() {
    return (
        <>

            <Layout>
                <Container maxWidth="xl" className='privacy-content-container-div'>
                    <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="privacy-notice-cls">
                        <Grid item xl={1} lg={1} className="hide">&nbsp;</Grid>
                        <Grid item xl={7} lg={8} md={12} sm={12} xs={12} className="border">
                            <PrivacyContent />
                        </Grid>
                        <Grid item xl={4} lg={3} className="hide">&nbsp;</Grid>
                    </Grid>
                </Container>
            </Layout>

        </>
    )
}