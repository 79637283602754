import React from 'react'
import './PrivacyContent.scss'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


export default function PrivacContent() {
  return (
    <>
      {/* Privacy Notice Starts */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography className='header-title'>privacy notice</Typography>
        <Typography className='sub-title'>last modified 19 sep 2021</Typography>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {/* overview */}
        <Typography className='paragraph'>We at Cartus (collectively, “Cartus”, “we”, “us”, or “our”) recognize the importance of protecting the privacy of personal information (as defined below in Section 1) collected by us. This Privacy Notice describes how Cartus collects, uses, discloses, retains, and transfers Personal Information in performing relocation and other services (the “Services”).
          It also describes your choices regarding use, access and correction of your Personal Information. Personal Information may be requested/collected to enable the delivery of our services, to operate our business and to make the experience of using our websites faster and more effective.</Typography>
        <Typography className='paragraph'>By using our website(s), you consent to the terms of this Privacy Policy and to Cartus’ processing of Personal Information for the purposes stated in this Privacy Notice, as well as those explained where Cartus collects Personal Information on the web. IF YOU DO NOT AGREE TO THIS PRIVACY NOTICE, YOU CANNOT USE OUR SERVICES.</Typography>

        <Typography className='para-title'>overview of our collection and use of personal information</Typography>
        <Typography className='paragraph'>We think transparency is important. In this overview, we summarize the Personal Information we collect and how we use it, which is further explained in our Privacy Notice below. Keep in mind that the actual Personal Information we collect and how we use it varies depending upon the nature of our relationship and interactions with you. Also, in some cases (such as where required by law), we ask for your consent or give you certain choices prior to collecting or using certain Personal Information.</Typography>
        <Typography className='paragraph'>Categories of Personal Information Collected. Generally, we collect the following types of Personal Information.</Typography>
        <ul className='bullet-list'>
          <li>Identifiers: includes direct identifiers such as a real name, alias, address, user ID, username, account number;
            phone number, unique personal identifier, online identifier, IP address, app ID, email address, account name,
            social security number, social insurance number, driver’s license number, passport number, or other similar identifiers.</li>
          <li>Customer records: includes Personal Information that individuals provide us in order to obtain our products and services,
            such as name, account name and number, signature, contact information, employment information, social security number,
            social insurance number, passport number, driver’s license and other government identifiers and financial information.
            For example, this may include information collected when an individual registers for our services.</li>
          <li>Commercial information: includes records of personal property, products or services purchased, obtained, or considered,
            or other purchasing or use histories or tendencies.</li>
          <li>Usage Data: includes browsing history, clickstream data, search history,
            and information regarding an individual’s interaction with an internet website, application, or advertisement.</li>
          <li>Geolocation data: includes precise location information about a particular individual or device.</li>
          <li>Audio, video and other electronic data: includes audio, electronic, visual or similar information such as thermal
            screenings and CCTV footage (e.g., collected from visitors to our offices), photographs, and call recordings
            (e.g., of customer support calls).</li>
          <li>Professional Information: includes professional or employment-related information.</li>
          <li>Education information: educational history and records.</li>
          <li>Inferences: inferences drawn from and of the above. Personal Information used to create a profile reflecting an individual’s preferences, behavior, or other characteristics.
          </li>
        </ul>

        {/* 01 Para */}
        <Typography className='para-title'>1. scope of our privacy notice</Typography>
        <Typography className='paragraph'>This Notice applies to our collection, use, disclosure, and other processing of Personal Information related to:</Typography>
        <ul className='bullet-list'>
          <li>the use of our Services that display or link to this Notice;</li>
          <li>former, current, and prospective clients and customers; and</li>
          <li>other individuals who use our Services, whose Personal Information we receive related to the Services, or who otherwise interact or communicate with us related to our Services</li>
        </ul>
        <Typography className='paragraph'>Our collection, use, disclosure, and processing of Personal Information about individuals will vary depending upon the circumstances. This Privacy Notice is intended to describe our overall privacy and data protection practices. In some cases, different or additional notices about our data collection and processing practices may be provided and apply to our processing of certain Personal Information.</Typography>
        <Typography className='paragraph'>Personal Information. In this Privacy Notice, our use of the term “Personal Information” includes other similar terms under applicable privacy laws—such as “personal data” and “personally identifiable information.” In general, Personal Information includes any information that identifies, relates to, describes, or is reasonably capable of being associated, linked, or linkable with a particular individual.</Typography>
        <Typography className='paragraph'>Not covered by this Notice. This Privacy Notice does not apply to job applicants and candidates who apply for employment with us, or to our employees and non-employee workers.</Typography>

        {/* 02 Para */}
        <Typography className='para-title'>2. our collection of personal information</Typography>
        <Typography className='paragraph'>Generally, we collect your Personal Information on a voluntary basis. However, if you decline to provide certain Personal Information that is marked mandatory, you may not be able to access certain Services or we may be unable to fully respond to your inquiry.</Typography>
        <Typography className='paragraph'>We may collect Personal Information directly from individuals, automatically related to the use of the Services, and in some cases, from third parties (such as a relocating employee’s employer and operators of certain third party services that we use).</Typography>
        <Typography className='paragraph'>Information we collect directly. We collect information that you provide to us, such as</Typography>
        <ul className='bullet-list'>
          <li>when you create an account, provide contact information, and send other Personal Information to us;</li>
          <li>when you complete or submit a form through our Services, for example, in CartusOnline or via “Contact Us” or “More Information” forms;</li>
          <li>when you participate in surveys, submit orders or requests, or register for or use our Services, or otherwise contact us;</li>
          <li>information you submit or provide such as information related to the purchase or sale of real estate.</li>
        </ul>
        <Typography className='paragraph'>Information we collect from third parties. We may collect Personal Information about you from third party sources, such as a relocating employee’s employer and from other third parties. For example, without limitation, if you choose to link, create, or log in to your Services account with a social media service (e.g., Twitter, Facebook, Instagram, etc.), or if you engage with a separate app or website that communicates with the Services, we may receive Personal Information about you or your connections from that service. In many cases you can control what Personal Information you share with us through privacy settings available on those third-party social media services.</Typography>

        {/* 03 Para */}
        <Typography className='para-title'>3. legal bases for our processing of personal information</Typography>
        <Typography className='paragraph'>Certain laws, including the EU General Data Protection Regulation (“GDPR”), require that we inform applicable individuals of the legal bases for our use and other processing of Personal Information.</Typography>
        <Typography className='paragraph'>Pursuant to the GDPR (and other relevant laws), we process Personal Information for the following legal bases:</Typography>
        <ul className='bullet-list'>
          <li>Performance of contract: as necessary to enter into or carry out the performance of a contract with you.</li>
          <li>Compliance with laws: for compliance with legal obligations and/or defense against legal claims, including those in the area of labor and employment law, social security, and data protection, tax, and corporate compliance laws.</li>
          <li>Our or our clients’ legitimate interests: in furtherance of our or our clients’ legitimate business interests including:
            <ul>
              <li>Performance of contracts, including for relocation services</li>
              <li>Implementation and operation of global support (e.g., IT) services for our business operations</li>
              <li>Customer relationship management and improving our Services, including marketing and analytics</li>
              <li>Fraud prevention, including misuse of company IT systems or money laundering</li>
              <li>Physical, IT, and network perimeter security</li>
              <li>Internal investigations</li>
              <li>Mergers, acquisitions, and reorganization, and other business transactions</li>
            </ul>
          </li>
          <li>With your consent: where we need and obtain your consent (the GDPR (where it applies)) and other applicable laws give you the right to withdraw your consent, which you can do at any time by contacting us using the details at the end of this Privacy Notice. In some jurisdictions, your use of the Services may be taken as implied consent to the collection and processing of Personal Information as outlined in this Privacy Notice.</li>
        </ul>
        <Typography className='paragraph'>In addition, we may process your Personal Information where necessary to protect the vital interests of any individual.</Typography>

        {/* 04 Para */}
        <Typography className='para-title'>4. use of personal information</Typography>
        <Typography className='paragraph'>We process your Personal Information described above as necessary for Cartus to provide relocation services under the terms of its contract with your employer or membership group consistent with the legitimate interests of your employer or membership group (i.e., our corporate or government entity client). If you provide us with sensitive Personal Information, we will process it based on your consent. In general, we collect, use, disclose, retain and transfer Personal Information for the purposes of managing and facilitating your move from one location to a new location (the “Relocation Purpose”), including the following to the extent authorized by your employer or membership group: (a) arranging for your physical move and the storage of your property; (b) tracking and processing expense reimbursements and other benefits, such as lump sum payments; (c) home sales, purchases, and rentals, including financing arrangements; (d) explaining various employer-sponsored benefits; (e) immigration and other customs related purposes; (f) referring you to third parties who provide cultural assistance and/or language training; and (g) other activities relating to the delivery and improvement of relocation services. Any access to your Personal Information is restricted to those individuals that have a need to know to fulfill their job responsibilities. We will not use Personal Information for any other purpose incompatible with the Relocation Purpose outlined in this Privacy Notice, unless it is required or authorized by laws that apply to Cartus or otherwise authorized by you.</Typography>
        <Typography className='paragraph'>In addition to the Relocation Purpose we also use Personal Information for the purposes set forth below. Where GDPR or other relevant laws apply, we have set forth the legal bases for such processing.</Typography>
        <ul className='bullet-list'>
          <li>Providing support and services: including, for example, to send or facilitate communications with you, your employer or membership group, to provide the Services, provide products and services you or your employer request (and send related information), operate our Services; to communicate with you about your access to and use of our Services; to respond to your inquiries; to provide troubleshooting, fulfill your requests and provide technical support; and for other customer service and support purposes. (Legal basis: performance of our contract with you or your employer or membership group; and our and our clients’ legitimate interests)</li>
          <li>Analyzing and improving our business: including to better understand how users access and use our Services, to evaluate and improve our Services and business operations, and to develop new features, offerings, and services; to conduct surveys, and other evaluations, such as customer satisfaction surveys; and for other research and analytical purposes. (Legal basis: our legitimate interests)</li>
          <li>Personalizing content and experiences: including to provide or recommend features, content, social connections, and referrals; tailor content we send or display on our Services; to offer location customization and personalized help and instructions; and to otherwise personalize your experiences. (Legal basis: our legitimate interests and/or with your consent)</li>
          <li>Advertising, marketing and promotional purposes: including to evaluate, measure, and improve the effectiveness of our ad campaigns; to send you newsletters, offers, or other information we think may interest you; and to contact you about our Services or information we think may interest you. (Legal basis: our legitimate interests and/or with your consent)</li>
          <li>Securing and protecting our business: including to protect and secure our business operations, assets, Services, network and information and technology resources; to investigate, prevent, detect and take action regarding fraud, unauthorized access, situations involving potential threats to the rights or safety of any person or third party, or other unauthorized activities or misconduct. (Legal basis: our legitimate interests and/or compliance with laws)</li>
          <li>Defending our legal rights: including to manage and respond to actual and potential legal disputes and claims, and to otherwise establish, defend or protect our rights or interests, including in the context of anticipated or actual litigation with third parties. (Legal basis: our legitimate interests and/or compliance with laws)</li>
          <li>Auditing, reporting, corporate governance, and internal operations: including relating to financial, tax and accounting audits; audits and assessments of our operations, privacy, security and financial controls, risk, and compliance with legal obligations; our general business, accounting, record keeping and legal functions; and related to any actual or contemplated merger, acquisition, asset sale or transfer, financing, bankruptcy or restructuring of all or part of our business. (Legal basis: our legitimate interests and/or compliance with laws)</li>
          <li>Complying with legal obligations: including to comply with the law, our legal obligations and legal process, such warrants, subpoenas, court orders, and regulatory or law enforcement requests. (Legal basis: our legitimate interests and/or compliance with laws)</li>
        </ul>
        <Typography className='paragraph'>Aggregate and de-identified information. We may de-identify Personal Information and create anonymous and aggregated data sets and reports in order to assess, improve and develop our business, products and services, prepare benchmarking reports on our industry and for other research, marketing and analytics purposes.</Typography>

        {/* 05 Para */}
        <Typography className='para-title'>5. disclosure of personal information</Typography>
        <Typography className='paragraph'>We may disclose the Personal Information that we collect about you as set forth below or as otherwise described at the time of collection or sharing.</Typography>
        <ul className='bullet-list'>
          <li>Service providers: We may disclose Personal Information with third-party service providers who use this information to perform services for us, such as hosting providers, auditors, advisors, consultants, and customer service and support providers.</li>
          <li> Subsidiaries and affiliates: We may share your Personal Information with our affiliated companies (i.e., companies under common ownership, control or management with us) - use will be subject to this Privacy Notice.</li>
          <li>Third parties: We may disclose Personal Information to third parties in order to provide our Services, facilitate your requests, or with your acknowledgement and consent, including with:
            <ul>
              <li>independent real estate professionals, moving services providers, immigration and visa service providers to enable them to provide services you request through the Services.</li>
              <li>other third parties to provide you a service you or your employer requested.</li>
              <li>the public if you submit information, such as comments, social media posts, or other features viewable by the public.</li>
              <li>third parties with whom you choose to let us share information, for example other apps or websites that integrate with the Services, or those with Services with which we integrate.</li>
              <li>third parties we work with to provide you with more personalized content, and to track the effectiveness of campaigns and our Services.</li>
            </ul>
          </li>
          <li>With your consent: where we need and obtain your consent (the GDPR (where it applies)) and other applicable laws give you the right to withdraw your consent, which you can do at any time by contacting us using the details at the end of this Privacy Notice. In some jurisdictions, your use of the Services may be taken as implied consent to the collection and processing of Personal Information as outlined in this Privacy Notice.</li>
          <li>Business transfers: We may disclose or transfer Personal Information as part of any actual or contemplated merger, sale, and transfer of our assets, acquisition, financing or restructuring of all or part of our business, bankruptcy or similar event, including related to due diligence conducted prior to such event where permitted by law.</li>
          <li>Legally required: We may disclose Personal Information if we are required to do so by law (e.g., to law enforcement, courts or others, e.g., in response to a subpoena or court order).</li>
          <li>Protect our rights: We may disclose Personal Information where we believe it necessary to respond to claims asserted against us or, comply with legal process (e.g., subpoenas or warrants), enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and to protect the rights, property, or safety of us, our clients and customers or others.</li>
        </ul>
        <Typography className='paragraph'>Anonymized and Aggregated Data. We may share aggregate or de-identified information with third parties for research, marketing, advertising, analytics, and other purposes.</Typography>

        {/* 06 Para */}
        <Typography className='para-title'>6. cookies, tracking and analytics</Typography>
        <Typography className='paragraph'>Our Services may use first party and third-party cookies, pixel tags, plugins, and other tools to gather device, usage and browsing information when users visit our websites. For instance, when you visit our websites, our server may record your IP address (and associated location information) and other information such as the type of your internet browser, your Media Access Control (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and model, language, and the pages you view and links you select on the Services, as well as date and time stamps associates with your activities on our Services.</Typography>
        <Typography className='paragraph'>We use the information for security purposes, to facilitate navigation, to personalize and improve your experience while using the Services, to improve and measure our advertising campaigns and to better reach users. We also gather statistical information about use of the Services in order to continually improve their design and functionality, understand how they are used and assist us with resolving questions regarding them.</Typography>
        <Typography className='paragraph'><span className="anchor-link">Cookies</span>. Cookies are small text files that a website transfers to your hard drive to store and sometimes collect information about your usage of websites, such as time spent on the websites, pages visited, language preferences, and other anonymous traffic data. You can control the way in which cookies are used by altering your browser settings. You may refuse to accept cookies by activating the setting on your browser that allows you to reject cookies. However, if you select such a setting, this may affect the functioning of our sites. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you access or log on to our Service.</Typography>
        <ul className='bullet-list'>
          <li>Essential cookies are essential, as they enable you to move around the Service and use its features. Without these cookies we could not, for example, authenticate your login or remember that you are logged-in as you move through the Service.</li>
          <li> Functionality cookies allow us to remember choices you make (such as the region you’re in) and tailor the Service to provide enhanced features and content for you. For instance, they can be used to remember your login details or remember your nickname when you next login. They may also be used to provide services you’ve requested, for example leaving a comment on the Service.</li>
          <li>Analytics & performances cookies collect information about how the Service is used, for example which pages users visit most often and if any error messages are displayed on certain pages. Information collected using these cookies is used to monitor activity on the Service and understand how it is being used.</li>
        </ul>
        <Typography className='paragraph'>If you have disabled one or more cookies, we may still use information collected from cookies before your disabled preference was set. However, we will stop using the disabled cookie to collect any further information.</Typography>
        <Typography className='paragraph'> To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="#." className="anchor-link">www.aboutcookies.org</a>.</Typography>
        <Typography className='paragraph'>Pixel tags and other similar technologies. Pixel tags (also known as web beacons and clear GIFs) may be used in connection with some Websites to, among other things, track the actions of users of the Websites (including email recipients), measure the success of our marketing campaigns and compile statistics about usage of the Websites and response rates. We and our service providers may also use pixel tags in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, track whether our emails are forwarded, and conduct analytics.</Typography>
        <Typography className='paragraph'>Log files. Most browsers collect certain information, such as your IP address, device type, screen resolution, operating system version and internet browser type and version. This information is gathered automatically and stored in log files.</Typography>
        <Typography className='paragraph'>Third party analytics tools. Our Websites use automated devices and applications operated by third parties, such as Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the Websites and report on activities and trends. This service may also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices by going to www.google.com/policies/privacy/partners/, and you can opt out of them by downloading the Google Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.</Typography>
        <Typography className='paragraph'>Do-Not-Track signals. Please note that our Websites do not recognize or respond to any signal which your browser might transmit through the so-called ‘Do Not Track’ feature your browser might have. If you wish to disable cookies on our Websites, you should not rely on any ‘Do Not Track’ feature your browser might have. For more information about do-not-track signals, please review <a href="#." className="anchor-link">https://allaboutdnt.com/</a></Typography>

        {/* 07 Para */}
        <Typography className='para-title'>7. interest based advertising</Typography>
        <Typography className='paragraph'>On some of our Websites, we may work with third-party ad networks, analytics companies, measurement services and others (“third-party ad companies”) to display advertising of our Services and to manage our advertising on third-party sites, mobile apps and online services. We and these third-party ad companies may use cookies, pixels tags and other tools to collect information on our Websites (and on third-party sites and services), such as browsing history, IP address, device ID, cookie and advertising IDs, and other identifiers, general location information and, with your consent, your device’s geolocation information; we and these third-party ad companies use this information to provide you more relevant ads and content and to evaluate the success of such ads and content.</Typography>
        <Typography className='paragraph'>You can manage how your preferences regarding third party ad company cookies set by reading the cookie discussion above.</Typography>
        <Typography className='paragraph'>Please also see the cookie discussion above for information about the third parties we may work with to display targeted ads on third-party sites and to measure the success of our marketing and campaigns. You may also obtain more information about targeted or “interest-based advertising” and opt-out of many ad networks at the industry websites below:</Typography>
        <ul className='bullet-list'>
          <li>Canada: <a href="#." className="anchor-link">www.youradchoices.ca</a></li>
          <li>EU: <a href="#." className="anchor-link">www.youronlinechoices.eu</a></li>
          <li>U.S.: <a href="#." className="anchor-link">www.aboutads.info</a></li>
        </ul>

        {/* 08 Para */}
        <Typography className='para-title'>8. social features and third party plug ins</Typography>
        <Typography className='paragraph'>Some of our Services may integrate with social sharing features and other related tools which let you log in to the Services or share actions you take on the Services. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share through them. We do not receive or store passwords to your third-party social media accounts in this context.</Typography>

        {/* 09 Para */}
        <Typography className='para-title'>9. privacy shield withdrawal and international transfers of personal information</Typography>
        <Typography className='paragraph'>Cartus has withdrawn our certifications under the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework (collectively, “Privacy Shield”), meaning that we no longer participate in Privacy Shield or comply with the Privacy Shield. In accordance with Privacy Shield requirements, with respect to personal data that we previously collected pursuant to Privacy Shield and are retaining, we will continue to apply the Privacy Shield Principles, and annually confirm to the U.S. Department of Commerce our commitment to apply those principles to such data. For more information about Privacy Shield, please visit <a href="#." className="anchor-link">https://www.privacyshield.gov/</a>.</Typography>
        <Typography className='paragraph'>Our Websites are hosted on servers located in the United States or European Union. Note that some countries may not provide the same level of data protection as your home country. If you are located, for example, in European Economic Area or Switzerland (collectively, “EEA”), your Personal Information will be transferred to the United States and possibly to other jurisdictions (e.g., to your relocation departure and destination location), which may not provide the same level of protection under your local data protection laws. In such cases, we rely on suitable Personal Information transfer safeguards, such as the European Union standard contractual clauses. You may have the right to request a copy or information about those safeguards by contacting us as detailed below.</Typography>

        {/* 10 Para */}
        <Typography className='para-title'>10. security</Typography>
        <Typography className='paragraph'>We use technical, administrative, and physical controls in place to help protect Personal Information from unauthorized access, use, and disclosure. Even so, despite our reasonable efforts, no security measure is ever perfect or impenetrable. In the event that the security of your account has been compromised, please immediately notify us in accordance with Section 15 below.</Typography>

        {/* 11 Para */}
        <Typography className='para-title'>11. data retention</Typography>
        <Typography className='paragraph'>We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a longer retention period is required or permitted by law. We may retain Personal Information for longer where required by our legal and regulatory obligations, professional indemnity obligations, or where we believe it is necessary to establish, defend or protect our legal rights and interests or those of others. With respect to the data and files we handle as a processor, we retain this information in accordance with applicable law and our clients’ instructions.</Typography>

        {/* 12 Para */}
        <Typography className='para-title'>12. children</Typography>
        <Typography className='paragraph'>Our Websites are not directed to children under the age of 16 (“Children”), and we do not knowingly collect any information, including Personal Information, from Children.</Typography>

        {/* 13 Para */}
        <Typography className='para-title'>13. third-party websites</Typography>
        <Typography className='paragraph'>Our Websites may contain links to third-party services. We do not own, operate, or control the websites of third-parties. Accordingly, this Notice does not apply to any services maintained or operated by third-parties. When you click on those links, you will go to a third-party website where you will be subject to that service’s privacy policy or similar statement and terms of use, and we encourage you to read that policy statement. We are not responsible for the privacy practices of other services, and we expressly disclaim any liability for their actions, including actions related to the use and disclosure of Personal Information by those third parties.</Typography>

        {/* 14 Para */}
        <Typography className='para-title'>14. changes to this notice</Typography>
        <Typography className='paragraph'>We may amend this Notice at any time. If we make any material change in how we collect, use, disclose, or otherwise process Personal Information, we will prominently post a notice regarding such change on our Websites or by other means we deem appropriate. Any material changes to this Notice will be effective 10 days after our posting of the updated Notice or as otherwise required by applicable law. Where required to do so by law, we may seek your prior consent to any material changes we make to this Notice.</Typography>

        {/* 15 Para */}
        <Typography className='para-title'>15. contacting us/correction/updating personal information/exercising your rights</Typography>
        <Typography className='paragraph'>You may contact us through the methods stated below for a number of purposes, including: (1) if you no longer wish to use the site; (2) to opt-out of communications or unsubscribe from any industry updates; (3) to receive information or special offers; (4) to provide feedback and comments on this Privacy Notice; (5) to request access to your Personal Information, deletion of your incorrect Personal Information or correction of your Personal Information; and (6) to make inquiries whether we hold or process on behalf of a third party, any of your Personal Information or complaints/objections regarding our Privacy Notice and/or privacy practices.</Typography>
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='table-content'>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='header-row bg-strip'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><Typography className='paragraph'>By e-mail to:</Typography></Grid>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}><a href="#." className='anchor-link'>InformationProtection@cartus.com</a></Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='header-row'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><Typography className='paragraph'>By mail:</Typography></Grid>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
              <Typography className='paragraph'>Privacy Officer, Cartus, 40 Apple Ridge Road, Danbury, CT 06810</Typography>
              <Typography className='paragraph'>if your relocation is being handled by our UK affiliate:</Typography>
              <Typography className='paragraph'>Director of Legal Services, Cartus Limited, Frankland Road, Blagrove, Swindon, Wiltshire SN5 8RS</Typography>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='header-row bg-strip'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><Typography className='paragraph'>By phone:</Typography></Grid>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}><Typography className='paragraph'>(203) 205-1540</Typography></Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='header-row'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><Typography className='paragraph'>Toll free U.S. phone:</Typography></Grid>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}><Typography className='paragraph'>(203) 205-1540</Typography></Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='header-row bg-strip'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}><Typography className='paragraph'>By phone (Singapore):</Typography></Grid>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}><Typography className='paragraph'>(203) 205-1540</Typography></Grid>
          </Grid>
        </Grid>
        <Typography className='paragraph'>We will respond to your request within a reasonable timeframe, but in no event later than 45 days, or such lesser period prescribed by applicable law.</Typography>
        <Typography className='paragraph'>ADDITIONAL INFORMATION FOR RESIDENTS OF CERTAIN JURISDICTIONS</Typography>

        {/* 15 A Para */}
        <Typography className='para-title'>15 (a). european union/european economic area/united kingdom:</Typography>
        <Typography className='paragraph'>If you are a resident of the EU, European Union or United Kingdom you may have the following rights that you should exercise through the contacts listed above or directly through your employer or membership group. Cartus will respond to you or aid your employer when you exercise the following rights:</Typography>
        <ul className='bullet-list list2'>
          <li>The right to require a copy of your personal data; </li>
          <li>The right to require that inaccurate or out of date personal data be corrected;</li>
          <li>The right to request that personal data is erased when it is no longer necessary to be retained;</li>
          <li>If consent it the basis for processing, the right to withdraw your consent;</li>
          <li>The right to data portability, if applicable;</li>
          <li>The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a restriction be placed on further processing;</li>
          <li>The right to object to processing, if applicable; and</li>
          <li>You also have the right to lodge a complaint with your applicable data protection authority.</li>
        </ul>

        {/* 15 B Para */}
        <Typography className='para-title'>15 (b). california residents (last updated: 19 january 2021)</Typography>
        <Typography className='paragraph'>In this section we provide information for California residents, as required under California privacy laws, including the California Consumer Privacy Act (“CCPA”), which requires that we provide California residents certain specific information about how we handle their Personal Information, whether collected online or offline. This section does not address or apply to our handling of publicly available information made lawfully available by state or federal governments or other Personal Information that is subject to an exemption under CCPA.</Typography>
        <Typography className='paragraph'>Categories of Personal Information that we collect and disclose. Our collection, use and disclosure of Personal Information about a California resident will vary depending upon the circumstances and nature of our interactions or relationship with such resident. The table below sets out generally the categories of Personal Information (as defined by the CCPA) about California residents that we collect and disclose to others for a business purpose.</Typography>
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className='table-content'>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='header-row bg-strip'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Categories of Personal information</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}><Typography className='paragraph'>May We Disclose this Information?</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Categories of Third Parties to Whom We May Disclose this Information</Typography></Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Identifiers: includes direct identifiers such as a real name, alias, address, user ID, username, account number; phone number, unique personal identifier, online identifier, IP address, app ID, email address, account name, social security number, social insurance number, driver’s license number, passport number, or other similar identifiers.</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row bg-strip'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Customer records: includes Personal Information that you or your employer or membership group provide us in order to obtain our products and services, such as name, account name and number, signature, contact information, employment information, social security number, social insurance number, passport number, driver’s license and other government identifiers, insurance information and financial information. For example, this may include information collected when an individual registers for our services.</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Commercial information: includes records of personal property, products or services purchased, obtained, or considered, or other purchasing or use histories or tendencies.</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row bg-strip'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Usage Data: includes browsing history, clickstream data, search history, and information regarding a resident’s interaction with an internet website, application, or advertisement, including access logs and other usage data related to your use of any company websites, applications or other online services.</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Geolocation data: includes precise location information about a particular individual or device.</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row bg-strip'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Audio, video and other electronic data: includes audio, electronic, visual, thermal, olfactory, or similar information such as thermal screenings and CCTV footage (e.g., collected from visitors to our offices), photographs you provide to us, and call recordings (e.g., of customer support calls).</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Professional Information: includes professional or employment-related information.</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='content-row bg-strip'>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}><Typography className='paragraph'>Education information: includes information about an individual’s educational history (such as the schools attended, degrees you were awarded, and associated dates).</Typography></Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='txt-center'><Typography className='paragraph'>Yes</Typography></Grid>
            <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
              <ul className='bullet-list list2'>
                <li>service providers</li>
                <li>advisors and agents</li>
                <li>government entities and law enforcement</li>
                <li>affiliates and subsidiaries</li>
                <li>real estate professionals</li>
                <li>your general location to data analytics providers</li>
                <li>internet service providers</li>
                <li>business customer/client</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Typography className='paragraph'>In addition, we may disclose any of the categories of Personal Information we collect about you to other third parties as directed by or consented to by you, or where necessary to fulfil your or your employer’s or membership group’s specific requests.</Typography>
        <Typography className='paragraph'>Do We “Sell” Personal Information? The CCPA defines a “sale” as disclosing or making available to a third-party Personal Information in exchange for monetary or other valuable consideration. While we do not disclose Personal Information to third parties in exchange for monetary compensation, as defined by the CCPA, we may “sell” Identifiers, Usage Data and Inferences to ad networks and data analytics providers, via cookies and tags on certain of our websites (e.g., in order to improve and measure our advertising and marketing campaigns).</Typography>
        <Typography className='paragraph'>Sources of Personal Information. As further described above, we may collect Personal Information from the following sources:</Typography>
        <ul className='bullet-list list2'>
          <li>directly from the individual</li>
          <li>advertising networks</li>
          <li>social networks</li>
          <li>government entities</li>
          <li>public records</li>
          <li>data analytics providers</li>
          <li>internet service providers</li>
          <li>operating systems and platforms</li>
          <li>our business clients</li>
        </ul>
        <Typography className='paragraph'>Rights of California Residents. California residents have the following rights under CCPA:</Typography>
        <ul className='bullet-list'>
          <li>Do-not-sell (opt-out): to opt-out of our sale of their Personal Information. The link to opt out can be found in the footer of this website.</li>
          <li>Deletion: to request deletion of their personal information that we have collected about them and to have such Personal Information deleted, subject to certain exemptions.</li>
          <li>Request to Know: with respect to Personal Information that we have collected about them in the prior 12 months, to require that we disclose the following to them (up to twice per year and subject to certain exceptions):
            <ul>
              <li>categories of personal information collected;</li>
              <li>categories of Personal Information that we have disclosed or shared with a third party for a business purpose or sold;</li>
              <li>categories of third parties to whom we have disclosed for a business purpose or sold their Personal Information;</li>
              <li>the business or commercial purposes for collecting or selling their Personal Information; and</li>
              <li>a copy of the specific pieces of Personal Information we have collected about them.</li>
            </ul>
          </li>
          <li>Right to non-discrimination: the right not to be subject to discriminatory treatment for exercising their rights under the CCPA.</li>
        </ul>
        <Typography className='paragraph'>We will respond to verifiable requests received from California residents as required by law.</Typography>
        <Typography className='paragraph'>Financial Incentives. We may make available certain programs or offerings that are considered “financial incentives” under the CCPA as described below.
          We will provide you with notice and obtain your consent prior to your participation in any such program.</Typography>
        <Typography className='paragraph'>Submitting Requests. Requests to opt-out of sales, requests to know, and requests to delete may be submitted:</Typography>
        <ul className='bullet-list'>
          <li>By email to <a href="#." className='anchor-link'>InformationProtection@cartus.com</a></li>
          <li>All requests: By contacting us at (877) 651-7356 (toll free)</li>
        </ul>
        <Typography className='paragraph'>When you submit a request to know or a request to delete, we will take steps to verify your request by matching the information provided by you with the information we have in our records. You must provide us with any requested information or otherwise provide us with this information via the above toll-free number to verify your request. In some cases, we may request additional information in order to verify your request or where necessary to process your request. If we are unable to adequately verify a request, we will notify the requestor. Authorized agents may initiate a request on behalf of another individual by contacting us at <a href="#." className='anchor-link'>InformationProtection@cartus.com</a>; authorized agents will be required to provide proof of their authorization and we may also require that the relevant consumer directly verify their identity and the authority of the authorized agent.</Typography>
        <Typography className='paragraph'>Rights Under California Shine the Light Law. California residents may request from businesses with whom they have an established business relationship (1) a list of categories of personal information, such as name, address, e-mail address, and the type of services provided to the customer, that a business has disclosed to third parties during the immediately preceding calendar year for the third parties’ direct marketing purposes and (2) the names and addresses of all such third parties. Cartus does not provide Personal Data to third parties for direct marketing purposes.</Typography>
      </Grid>
      {/* Privacy Notice eEds */}
    </>
  )
}


